<template>
  <a-layout-sider
    collapsible
    v-model="collapsed"
    @collapse="showSidebar"
    width="270"
    collapsedWidth="40"
    style="max-width: 300px !important;padding-top: 5px"
  >
    <!-- <div class="logo">
      <img src="@/assets/viettel_logo.jpg" alt="Viettel">
    </div> -->
    <div
      class="ant-layout-sider-trigger sider--top cl-sider--top"
      style="width: 270px;"
      @click="() => {collapsed = !collapsed; showSidebar()}"><i
        aria-label="icon: left"
        class="anticon anticon-left"
        id="icon-siderbar">
        <svg
          viewBox="64 64 896 896"
          data-icon="left"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="false"
          focusable="false"
          class="">
          <path
            d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
        </svg>
      </i></div>
    <a-menu
      mode="inline"
      class="ant-menu_1"
      :defaultSelectedKeys="[currentKey]"
      :selected-keys="[currentKey]"
      :default-open-keys="[openKeys]"
      :style="{ height: '100%', borderRight: 0, paddingTop: '0', }"
    >
      <a-menu-item v-if="$auth.hasPrivilege('PRE_ORDER_MANAGEMENT') === true" key="2" >
        <router-link :to="{ name: 'pre_order_management' }" class="title-menu">
          <span>Đơn đặt hàng</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="$auth.hasAnyPrivilege(['VOUCHER_MANAGEMENT','VOUCHER_MANAGEMENT_DETAIL', 'VOUCHER_MANAGEMENT_UPDATE']) === true" key="3" >
        <router-link :to="{ name: 'voucher_management' }" class="title-menu">
          <span>Nhập xuất hàng</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="$auth.hasPrivilege('WAREHOUSE_MANAGEMENT') === true" key="4" >
        <router-link :to="{ name: 'warehouse_management' }" class="title-menu">
          <span>Danh mục kho</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="$auth.hasPrivilege('SCAN_DEVICE_MANAGEMENT') === true" key="5" >
        <router-link :to="{ name: 'scan_device_management' }" class="title-menu">
          <span>Máy quét mã vạch</span>
        </router-link>
      </a-menu-item>
      <a-menu-item v-if="$auth.hasAnyPrivilege(['USER_MANAGEMENT','ROLE_MANAGEMENT']) === true" key="6" >
        <router-link :to="{ name: 'config' }" class="title-menu">
          <span>Cấu hình</span>
        </router-link>
      </a-menu-item>
      <a-sub-menu
        key="7"
      >
        <a slot="title">
          <span class="title-menu-sub">BÁO CÁO</span></a>
        <a-menu-item key="7_1" v-if="$auth.hasPrivilege('RP_IMPORT') === true">
          <router-link :to="{ name: 'rp_import' }" class="title-menu-sub-child">
            <span>Nhập hàng</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="7_2" v-if="$auth.hasPrivilege('RP_IMPORT_BY_PLATE') === true">
          <router-link :to="{ name: 'rp_import_by_plate' }" class="title-menu-sub-child">
            <span>Nhập hàng theo xe</span>
          </router-link>
        </a-menu-item>
        <!--        <a-menu-item key="7_3" >-->
        <a-menu-item key="7_3" v-if="$auth.hasPrivilege('RP_SUMMARY') === true">
          <router-link :to="{ name: 'rp_summary' }" class="title-menu-sub-child">
            <span>Tổng hợp</span>
          </router-link>
        </a-menu-item>
      </a-sub-menu>
      <a-menu-item key="8" v-if="$auth.hasPrivilege('PLAN_RECEIVE_MANAGEMENT')">
        <router-link :to="{ name: 'delivery_plan' }" class="title-menu">
          <span>Kế hoạch nhận hàng</span>
        </router-link>
      </a-menu-item>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SideBar',
  data () {
    return {
      collapsed: false
    }
  },
  computed: {
    ...mapGetters('permission', ['addRouters']),
    currentKey: function () {
      let idx = '0'
      let routeName = this.$router.currentRoute.name
      const fromSource = this.$route.query.from
      if (fromSource) {
        routeName = fromSource
      }
      switch (routeName) {
        case 'dashboard':
          idx = '1'
          break
        case 'pre_order_management':
        case 'pre_order_management.detail':
          idx = '2'
          break
        case 'voucher_management_detail':
        case 'voucher_management':
          idx = '3'
          break
        case 'warehouse_management':
          idx = '4'
          break
        case 'scan_device_management':
          idx = '5'
          break
        case 'config':
        case 'config.create':
        case 'config.update':
        case 'role_management':
          idx = '6'
          break
        case 'rp_import':
          idx = '7_1'
          break
        case 'rp_import_by_plate':
          idx = '7_2'
          break
        case 'rp_summary':
          idx = '7_3'
          break
        case 'delivery_plan':
          idx = '8'
          break
      }

      return idx
    },
    openKeys: function () {
      let k = '0'
      switch (this.$router.currentRoute.name) {
        case 'dashboard':
          k = '1'
          break
        case 'pre_order_management':
        case 'pre_order_management.detail':
          k = '2'
          break
        case 'voucher_management':
        case 'voucher_management_detail':
          k = '3'
          break
        case 'warehouse_management':
          k = '4'
          break
        case 'scan_device_management':
          k = '5'
          break
        case 'config':
        case 'config.create':
        case 'config.update':
        case 'role_management':
          k = '6'
          break
        case 'rp_import':
        case 'rp_import_by_plate':
        case 'rp_summary':
          k = '7'
          break
        case 'delivery_plan':
          k = '8'
          break
        default:
          k = '0'
      }
      return k
    }
  },
  mounted () {
  },
  methods: {
    hasPermission (routeName) {
      /*
      if (!this.addRouters) return false
      const flag = this.addRouters.filter(item => item.name === permission)
      if (flag.length > 0) {
        return true
      }
      return true
      */
      return this.hasMenuPermission(routeName)
    },
    hasPermissions (routeNames) {
      const $this = this
      let ok = true
      routeNames.forEach(function (routeName) {
        if ($this.hasPermission(routeName) === false) {
          ok = false
        }
      })
      return ok
    },
    hasAnyPermissions (paths) {
      const $this = this
      let ok = false
      paths.forEach(function (path) {
        if ($this.hasPermission(path) === true) {
          ok = true
        }
      })
      return ok
    },
    showSidebar () {
      if (this.collapsed === true) {
        document.getElementsByClassName('sider--top')[0].style.width = '40px'
        document.getElementById('icon-siderbar').style.transform = 'rotate(180deg)'
      } else {
        document.getElementsByClassName('sider--top')[0].style.width = '270px'
        document.getElementById('icon-siderbar').style.transform = 'rotate(0deg)'
      }
    }
  }
}
</script>
<style>
.ant-layout-sider-children {
  overflow: hidden;
  background-color: #976300!important;
}

.sider--top {
  top: 0 !important;
  position: absolute !important;
  background-color: #976300!important;
}

.ant-menu_1 {
  margin-top: 40px !important;
  background-color: #976300!important;
}

.cl-sider--top {
  background-color: #976300 !important;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 15px !important;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item > a {
  height: auto !important;
  line-height: normal;
}
.ant-menu-item{
  background-color: #976300!important;
}
.icon-menu {
  font-size: 24px!important;
}
.title-menu{
  font-size: 14px!important;
  /*font-weight: 400!important;*/
  color: white!important;
  font-weight: bold!important;
}
.title-menu-sub{
  font-size: 13px!important;
  /*font-weight: 400!important;*/
  padding-left: 0px;
  color: white!important;
  font-weight: bold!important;
}
.title-menu-sub-child{
  font-size: 13px!important;
  /*font-weight: 400!important;*/
  color: white!important;
  font-weight: bold!important;
}
.ant-menu-item-selected{
  background-color: #ffb300!important;
}
</style>
